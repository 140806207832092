import {
  CephFinding,
  FrontalFinding,
  OcclusionFinding,
  ORTHO_IMAGE_TYPE,
  OrthoCanvasPredictionObject,
  OrthoClaimPrediction,
  OrthoImagePredictions,
  PanoFinding,
  PosteriorFinding,
} from "./types";
import { OrthoPredictionFindings } from "../../containers/new-design/ortho/OrthoPredictionFindings";

export class OrthoClaimImagePrediction {
  claimLevelCanvasPrediction: OrthoCanvasPredictionObject;
  claimPrediction: OrthoClaimPrediction;

  constructor(claimLevelCanvasPrediction: OrthoCanvasPredictionObject) {
    this.claimLevelCanvasPrediction = claimLevelCanvasPrediction;
    this.claimPrediction = this.claimLevelCanvasPrediction.claimPrediction;
    this.parseContent();
  }

  getImagePredictions() {
    const listImagePredictions: OrthoImagePredictions = {};
    const gridingMapping = this.claimPrediction?.image_paths || {};
    Object.entries(ORTHO_IMAGE_TYPE).forEach(([key, imageType]) => {
      const imagePath = gridingMapping[imageType];
      if (imagePath?.img_id) {
        const orthoPredictionFindings = new OrthoPredictionFindings(
          imageType,
          this.claimLevelCanvasPrediction.imagePredictions[imagePath.img_id]
        );

        switch (imageType) {
          case ORTHO_IMAGE_TYPE.INTRA_OCCLUSAL_UPPER:
            const occlusionUpperFinding = this.getOcclusionUpperFinding();
            listImagePredictions[imagePath.img_id] =
              orthoPredictionFindings.getOcclusalUpperFindings(
                occlusionUpperFinding
              );
            break;
          case ORTHO_IMAGE_TYPE.INTRA_FRONTAL_REST:
            listImagePredictions[imagePath.img_id] =
              orthoPredictionFindings.getFrontalRestFindings();
            break;
          case ORTHO_IMAGE_TYPE.INTRA_OCCLUSAL_LOWER:
            const lowerFinding = this.getOcclusionLowerFinding();
            listImagePredictions[imagePath.img_id] =
              orthoPredictionFindings.getOcclusalLowerFindings(lowerFinding);
            break;
          case ORTHO_IMAGE_TYPE.INTRA_LATERAL_LEFT:
            const lateralLeftFinding = this.getLateralLeftFinding();
            listImagePredictions[imagePath.img_id] =
              orthoPredictionFindings.getLateralLeftFindings(
                lateralLeftFinding
              );
            break;
          case ORTHO_IMAGE_TYPE.INTRA_FRONTAL_OCCLUSION:
            const claimLevelMLFrontalFinding = this.getFrontalFinding();
            listImagePredictions[imagePath.img_id] =
              orthoPredictionFindings.getFrontalOcclusalFindings(
                claimLevelMLFrontalFinding
              );
            break;
          case ORTHO_IMAGE_TYPE.INTRA_LATERAL_RIGHT:
            const lateralRightFinding = this.getLateralRightFinding();
            listImagePredictions[imagePath.img_id] =
              orthoPredictionFindings.getLateralRightFindings(
                lateralRightFinding
              );
            break;
          case ORTHO_IMAGE_TYPE.PAN:
            const panoFinding = this.getPanoFinding();
            listImagePredictions[imagePath.img_id] =
              orthoPredictionFindings.getPanoFindings(panoFinding);
            break;
          case ORTHO_IMAGE_TYPE.CEPH:
            const claimCephFinding = this.getCephFinding();
            listImagePredictions[imagePath.img_id] =
              orthoPredictionFindings.getCephFindings(claimCephFinding);
            break;
          default:
            return null;
        }
      }
    });
    return listImagePredictions;
  }

  parseContent() {
    Object.entries(this.claimLevelCanvasPrediction.imagePredictions).forEach(
      ([key, imagePrediction]) => {
        if (typeof imagePrediction.content === "string") {
          imagePrediction.jsonContent = JSON.parse(imagePrediction.content);
        }
      }
    );
  }

  getOcclusionUpperFinding() {
    const occlusionUpperFinding = {
      ectopic: [],
      canine2canine_insufficiency: undefined,
      molar2molar_insufficiency: undefined,
      labio_lingual_spread: undefined,
      crowded: [],
      rotated: [],
      spacing_open_pairs: [],
      spacing_closed: [],
    } as OcclusionFinding;

    const maxilla = this.claimPrediction.intra_arch_deviation.maxilla;

    if (maxilla?.ectopic && maxilla.ectopic?.length) {
      occlusionUpperFinding.ectopic = [...maxilla.ectopic];
    }

    occlusionUpperFinding.canine2canine_insufficiency =
      maxilla?.canine2canine_insufficiency_round;

    occlusionUpperFinding.molar2molar_insufficiency =
      maxilla?.molar2molar_insufficiency_round;

    if (maxilla?.labio_lingual_spread_round) {
      // because of rounded values we should not draw line for 0
      occlusionUpperFinding.labio_lingual_spread =
        maxilla.labio_lingual_spread_round;
    }

    if (maxilla?.crowded && maxilla.crowded?.length) {
      occlusionUpperFinding.crowded = [...maxilla.crowded];
    }

    if (maxilla?.rotated && maxilla.rotated?.length) {
      occlusionUpperFinding.rotated = [...maxilla.rotated];
    }

    if (maxilla?.spacing_open_pairs && maxilla.spacing_open_pairs?.length) {
      occlusionUpperFinding.spacing_open_pairs = [
        ...maxilla.spacing_open_pairs,
      ];
    }

    if (maxilla?.spacing_closed && maxilla.spacing_closed?.length) {
      occlusionUpperFinding.spacing_closed = [...maxilla.spacing_closed];
    }

    return occlusionUpperFinding;
  }

  getOcclusionLowerFinding() {
    const occlusionLowerFinding = {
      ectopic: [],
      canine2canine_insufficiency: undefined,
      molar2molar_insufficiency: undefined,
      labio_lingual_spread: undefined,
      crowded: [],
      rotated: [],
      spacing_open_pairs: [],
      spacing_closed: [],
    } as OcclusionFinding;

    const mandible = this.claimPrediction.intra_arch_deviation.mandible;

    if (mandible?.ectopic && mandible.ectopic?.length) {
      occlusionLowerFinding.ectopic = [...mandible.ectopic];
    }

    occlusionLowerFinding.canine2canine_insufficiency =
      mandible?.canine2canine_insufficiency_round;

    occlusionLowerFinding.molar2molar_insufficiency =
      mandible?.molar2molar_insufficiency_round;

    if (mandible?.labio_lingual_spread_round) {
      occlusionLowerFinding.labio_lingual_spread =
        mandible.labio_lingual_spread_round;
    }

    if (mandible?.crowded && mandible.crowded?.length) {
      occlusionLowerFinding.crowded = [...mandible.crowded];
    }

    if (mandible?.rotated && mandible.rotated?.length) {
      occlusionLowerFinding.rotated = [...mandible.rotated];
    }

    if (mandible?.spacing_open_pairs && mandible.spacing_open_pairs?.length) {
      occlusionLowerFinding.spacing_open_pairs = [
        ...mandible.spacing_open_pairs,
      ];
    }

    if (mandible?.spacing_closed && mandible.spacing_closed?.length) {
      occlusionLowerFinding.spacing_closed = [...mandible.spacing_closed];
    }

    return occlusionLowerFinding;
  }

  getLateralLeftFinding() {
    const lateralLeft = {
      ectopic: [],
      crossbite: [],
      class_II_discrepancy: undefined,
      class_III_discrepancy: undefined,
      openbite: [],
    } as PosteriorFinding;

    const ectopicList = this.getEctopic();
    if (ectopicList?.length) {
      lateralLeft.ectopic?.push(...ectopicList);
    }

    const posteriorLeft =
      this.claimPrediction.inter_arch_deviation.posterior_left;
    if (posteriorLeft?.posterior_crossbite?.left_teeth?.length) {
      lateralLeft.crossbite = posteriorLeft.posterior_crossbite.left_teeth;
    }

    if (posteriorLeft?.class_II_discrepancy) {
      lateralLeft.class_II_discrepancy = posteriorLeft.class_II_discrepancy;
    }

    if (posteriorLeft?.class_III_discrepancy) {
      lateralLeft.class_III_discrepancy = posteriorLeft.class_III_discrepancy;
    }

    if (posteriorLeft?.posterior_openbite?.left_teeth?.length) {
      lateralLeft.openbite = posteriorLeft.posterior_openbite.left_teeth;
    }

    return lateralLeft;
  }

  getLateralRightFinding() {
    const lateralRight = {
      ectopic: [],
      crossbite: [],
      class_II_discrepancy: undefined,
      class_III_discrepancy: undefined,
      openbite: [],
    } as PosteriorFinding;

    const ectopicList = this.getEctopic();
    if (ectopicList?.length) {
      lateralRight.ectopic?.push(...ectopicList);
    }

    const posteriorRight =
      this.claimPrediction.inter_arch_deviation.posterior_right;
    if (posteriorRight?.posterior_crossbite?.right_teeth?.length) {
      lateralRight.crossbite = posteriorRight.posterior_crossbite.right_teeth;
    }

    if (posteriorRight?.class_II_discrepancy) {
      lateralRight.class_II_discrepancy = posteriorRight.class_II_discrepancy;
    }

    if (posteriorRight?.class_III_discrepancy) {
      lateralRight.class_III_discrepancy = posteriorRight.class_III_discrepancy;
    }

    if (posteriorRight?.posterior_openbite?.right_teeth?.length) {
      lateralRight.openbite = posteriorRight.posterior_openbite.right_teeth;
    }

    return lateralRight;
  }

  getFrontalFinding() {
    const frontalFinding = {
      ectopic: [],
      openbite: undefined,
      overbite: undefined,
      overjet: undefined,
      crossbite: [],
    } as FrontalFinding;

    const frontal = this.claimPrediction.inter_arch_deviation.frontal;

    const ectopicList = this.getEctopic();
    if (ectopicList?.length) {
      frontalFinding?.ectopic?.push(...ectopicList);
    }

    if (frontal?.openbite) {
      frontalFinding.openbite = frontal.openbite;
    }

    if (frontal?.overbite) {
      frontalFinding.overbite = frontal.overbite;
    }

    if (frontal?.overjet) {
      frontalFinding.overjet = frontal.overjet;
    }

    if (
      frontal?.crossbite &&
      frontal.crossbite?.frontal_teeth &&
      frontal.crossbite.frontal_teeth?.length
    ) {
      frontalFinding?.crossbite?.push(...frontal.crossbite.frontal_teeth);
    }

    return frontalFinding;
  }

  getPanoFinding() {
    const panoFinding = {
      ectopic: [],
      impacted: [],
    } as PanoFinding;

    const ectopicList = this.getEctopic();
    if (ectopicList?.length) {
      panoFinding?.ectopic?.push(...ectopicList);
    }
    const impactedList = this.getImpacted();
    if (impactedList?.length) {
      panoFinding?.impacted?.push(...impactedList);
    }

    return panoFinding;
  }

  getCephFinding() {
    const cephFinding = {
      overjet: undefined,
      overbite: undefined,
      reverse_overjet: undefined,
    } as CephFinding;

    const frontal = this.claimPrediction.inter_arch_deviation.frontal;

    if (frontal?.overbite && frontal?.overbite?.ceph_round) {
      cephFinding.overbite = frontal.overbite.ceph_round;
    }

    if (frontal?.overjet && frontal?.overjet?.ceph_round) {
      cephFinding.overjet = frontal.overjet.ceph_round;
    }

    if (
      frontal?.mandibular_protrusion &&
      frontal.mandibular_protrusion?.ceph_round
    ) {
      cephFinding.reverse_overjet = frontal.mandibular_protrusion.ceph_round;
    }

    return cephFinding;
  }

  getEctopic() {
    const mandible = this.claimPrediction.intra_arch_deviation.mandible;
    const maxilla = this.claimPrediction.intra_arch_deviation.maxilla;
    const ectopicList: string[] = [];

    if (maxilla?.ectopic && maxilla.ectopic?.length) {
      ectopicList.push(...maxilla.ectopic);
    }

    if (mandible?.ectopic && mandible.ectopic?.length) {
      ectopicList.push(...mandible.ectopic);
    }

    return ectopicList;
  }

  getImpacted() {
    const mandible = this.claimPrediction.intra_arch_deviation.mandible;
    const maxilla = this.claimPrediction.intra_arch_deviation.maxilla;
    const impactedList: string[] = [];

    if (maxilla?.impacted && maxilla.impacted?.length) {
      impactedList.push(...maxilla.impacted);
    }

    if (mandible?.impacted && mandible.impacted?.length) {
      impactedList.push(...mandible.impacted);
    }

    return impactedList;
  }
}
