import { GenericObject, IClaims } from "src/defs";
import { CanvasConfig } from "./user.service.interface";
import { ReportConfig } from "src/components/reporting/types";
import { ReviewerConfig } from "src/components/new-design/canvas/reviewer-actions/types";
import { AnnotationDisplayRule } from "src/components/new-design/canvas/types";
export interface PageConfig {
  size: number;
  offset: number;
}

export interface SortConfig {
  direction: string;
  property: string;
}

export interface PaginationConfig {
  page?: PageConfig;
  sort?: Array<SortConfig>;
}

export interface AnalyticsCard extends GenericObject {
  index: number;
  title: string;
  caption?: string;
  overjetReviewResult?: string;
  color?: string;
}

export interface HeaderConfig extends GenericObject {
  colName: string;
  displayName: string;
  checked: boolean;
}

export interface DashboardConfig {
  claimsTable?: ClaimsTable;
  claimTableTabs?: Array<any>;
  tableTabs?: Array<any>;
  analyticsCard?: Array<AnalyticsCard>;
  showSystemFilters?: boolean;
}

export interface ClaimsTable {
  fmxWorkflowEnabled?: boolean;
  claimLevel: boolean;
  columnsToHide: Array<string>;
}

export interface QueueConfig {
  analyticsCard: Array<AnalyticsCard>;
  queueHeaders: Array<string>;
  queueHeadersConfig: Array<HeaderConfig>;
  showSpecialtyFilter?: string;
}

export interface FraudWasteAbuse extends GenericObject {
  summaryHeaders: Array<any>;
  dashboardConfig: GenericObject;
  clientHeadersConfig: GenericObject;
}

export interface ClientConfig extends GenericObject {
  activeProcedures: Array<string>;
  canvasConfig?: CanvasConfig;
  claimCheckoutControlEnabled?: boolean;
  claimLevelCanvasConfig?: CanvasConfig;
  claimSummaryHeaders?: Array<string>;
  claims?: GenericObject;
  headersConfig?: Array<IClaims.IVisibleColsDictionary> | Array<GenericObject>;
  clientHeaders?: Array<string>;
  predictionConfig?: GenericObject;
  dashboardConfig?: DashboardConfig;
  queueConfig?: QueueConfig;
  claimLevelQueueConfig?: QueueConfig;
  fraudWasteAbuse: FraudWasteAbuse;
  tabConfig?: GenericObject;
  graphqlQueries?: GenericObject;
  claimLevelGraphqlQueries?: GenericObject;
  claimLevelDashboardConfig?: DashboardConfig;
  userDefaultRoute?: string;
  clientDefaultRoute?: string;
  defaultRoute?: string;
  reportsConfig: Array<ReportConfig>;
  clientName: string;
  inActivityLogoutPeriod?: string | number;
  showAttestationBox?: boolean;
  enableAccessibility?: boolean;
  reviewConfig?: Array<ReviewerConfig>;
  annotationDisplayRules?: Array<AnnotationDisplayRule>;
  colorDecisionField?: string;
  claimDeliveryType?: string;
}

export interface SAMLattributes {
  client_id: string;
  first_name: string;
  last_name: string;
  nickname: string;
  role: string;
  role_id: string;
}

export interface Authority {
  authority: string;
}

export interface UserInformation {
  stateLicenses?: GenericObject;
  firstName?: string;
  lastName?: string;
  emailId?: string;
  licenseSpeciality?: string;
}

export interface UserProfile {
  accountNonExpired?: boolean;
  accountNonLocked?: boolean;
  authorities: Array<Authority>;
  clientConfig: ClientConfig;
  permissionObject: GenericObject;
  email?: string;
  username?: string;
  roles?: Array<string>;
  userInformation?: UserInformation;
}

export interface PredictionConfigObject {
  anteriorRCTnoCrown?: string;
  autoAcceptPosteriorRCT?: string;
  autoApprove?: boolean;
  autoPend?: boolean;
  boneLevel?: number;
  boneLoss?: number;
  calculationRate?: number;
  calculusPresent?: boolean;
  decayOrFracturePresentForDMF?: boolean;
  decayPresentForDMF?: boolean;
  defectiveCrownPresent?: boolean;
  downgradeAllProcToOne?: boolean;
  downgradeCode?: string;
  downgradePlans?: Array<any>;
  existCrownNoRCTnoDecay?: string;
  existCrownWithRCTNoDecay?: string;
  fullDentureApproved?: boolean;
  furcationPresent?: boolean;
  juvenileAggressivePerio?: string;
  maxBoneRatio?: string;
  maxCrownImpacted?: string;
  maxDmfRatio?: number;
  minAge?: number;
  minBoneRatio?: string;
  minCrownImpacted?: string;
  minDmfRatio?: number;
  numberOfTeethPerQuadrant?: number;
  paidHistoryPresent?: boolean;
  posteriorExistCrownWithDecay?: string;
  rctObturation?: number;
  rctPresentOnPosteriorTooth?: boolean;
  reviewIfPAWithOcclusalOnly?: boolean;
  imageTypeConfig: GenericObject;
  ruleExecutionIdConfig?: GenericObject;
}

export interface PredictionConfigI {
  [procedure: string]: PredictionConfigObject;
}

export enum CanvasTypes {
  DEFAULT_CANVAS = "canvas",
  ORTHO_CANVAS = "ortho",
}

export interface ClaimDetails {
  canvasType: CanvasTypes;
}

export interface CachedClaimRecord {
  [key: string]: ClaimDetails;
}

export interface ClaimIDsCache {
  ids: Array<string>;
  cachedClaimRecords: CachedClaimRecord;
  pageOffset: number;
  size: number;
  totalElements: number;
}
