import { FindingI } from "../../../context/canvas/types";
import { v4 } from "uuid";
import { ORTHO_COLORS } from "../../../components/canvas/constant";
import {
  CephFinding,
  FrontalFinding,
  FrontalOverbite,
  OcclusionFinding,
  ORTHO_IMAGE_TYPE,
  OrthoImageFindingDetail,
  OrthoImagePrediction,
  OrthoImagePredictionI,
  OrthoImagePredictionOptions,
  PanoFinding,
  PosteriorFinding,
  SpacingOpen,
  Tooth,
} from "../../../context/ortho/types";
import { GenericObject } from "../../../defs";

export class OrthoPredictionFindings {
  public prediction: OrthoImagePredictionI;
  public polygons: FindingI = {};
  public sidePanel: OrthoImageFindingDetail[] = [];
  public imageUri: string = "";
  public imageType: string = "";
  public jsonContent: GenericObject = {};
  public listTeeth: { [key: string]: Tooth } = {};
  public pxToMM: number = 1;
  public enablePxToMM: boolean = true;

  public claimCephFinding?: CephFinding;
  public claimPanoFinding?: PanoFinding;
  public claimFrontalFinding?: FrontalFinding;
  public claimOcclusionUpperFinding?: OcclusionFinding;
  public claimOcclusionLowerFinding?: OcclusionFinding;
  public claimLateralLeftFinding?: PosteriorFinding;
  public claimLateralRightFinding?: PosteriorFinding;

  constructor(imageType: string, prediction: OrthoImagePredictionI) {
    this.imageType = imageType;
    this.prediction = prediction;
    this.initializePredictionDetails();
  }

  initializePredictionDetails() {
    if (typeof this.prediction.content === "string") {
      this.jsonContent = JSON.parse(this.prediction.content);
      this.imageUri = this.jsonContent?.image_loc;
      this.listTeeth = this.jsonContent?.tooth_number?.reduce(
        (acc: { [key: string]: Tooth }, value: Tooth) => {
          acc[value?.tooth_number_uns] = value;
          return acc;
        },
        {}
      );
      this.pxToMM = this.getPixelToMM();
    }
  }

  getOverBite() {
    const { overbite_line } = this.jsonContent.ortho_general?.measurements ?? {
      overbite_line: [],
      overbite_mm: 0,
    };

    const overbite_mm = this.claimCephFinding?.overbite ?? 0;

    if (overbite_line && overbite_line.length > 0 && overbite_mm > 0) {
      let overbiteLine = {
        uuid: v4(),
        color: ORTHO_COLORS.OVERBITE,
        geometry: [overbite_line],
        geometry_labels: overbite_mm,
        unit: "mm",
        hidden: false,
        key: "Overbite",
        parentKey: "overbite",
        polygonType: "line",
        vertical: true,
        pxToMM: this.pxToMM,
        enablePxToMM: this.enablePxToMM,
      };
      this.polygons[overbiteLine.uuid] = overbiteLine;

      this.sidePanel.push({
        key: "Overbite",
        description: "Overbite",
        value: `${overbite_mm} mm`,
        color: ORTHO_COLORS.OVERBITE,
      });
    }
  }

  getSpacingOpen() {
    if (this.jsonContent.ortho_general?.spacing_open?.length) {
      const validTnPair: string[] = [];
      const spacing_open = this.jsonContent.ortho_general?.spacing_open;
      spacing_open
        ?.sort(this.tnPairSort)
        .forEach((spacingOpen: SpacingOpen) => {
          const { bbox, space_mm, tn_pair } = spacingOpen ?? {
            bbox: [],
            space_mm: 0,
          };
          if (bbox?.length > 0 && space_mm) {
            let spacingOpenBox = {
              uuid: v4(),
              color: ORTHO_COLORS.SPACING_OPEN,
              geometry: [bbox],
              description: tn_pair.sort(this.numericSort).join("-"),
              key: "Spacing open",
              parentKey: "Spacing open",
              polygonType: "bbox",
              pxToMM: this.pxToMM,
              enablePxToMM: this.enablePxToMM,
            };
            this.polygons[spacingOpenBox.uuid] = spacingOpenBox;
            validTnPair.push(tn_pair.sort(this.numericSort).join("-"));
          }
        });

      if (validTnPair.length > 0) {
        this.sidePanel.push({
          key: "Spacing open",
          description: "Spacing open",
          value: validTnPair.join(", "),
          color: ORTHO_COLORS.SPACING_OPEN,
        });
      }
    }
  }

  getOverbiteBox(overbite: FrontalOverbite, key: string, color: string) {
    overbite?.frontal_upper_jaw
      ?.sort(this.numericSort)
      .forEach((toothNumber) => {
        if (toothNumber) {
          const { bbox, mm } = this.jsonContent.ortho_general?.frontal_findings
            ?.overbite_dict?.[toothNumber] ?? {
            bbox: [],
            mm: 0,
          };
          const mmRounded = mm?.toFixed(0) ?? 0;
          if (bbox?.length > 0 && mmRounded > 0) {
            let spacingOpenBox = {
              uuid: v4(),
              color: color,
              geometry: [bbox],
              geometry_labels: mmRounded,
              unit: "mm",
              hidden: false,
              key: `Overbite(${toothNumber})`,
              parentKey: "Overbite",
              polygonType: "bbox",
              pxToMM: this.pxToMM,
              enablePxToMM: this.enablePxToMM,
            };
            this.polygons[spacingOpenBox.uuid] = spacingOpenBox;
            this.sidePanel.push({
              key: `Overbite(${toothNumber})`,
              description: `Overbite(${toothNumber})`,
              value: `${mmRounded} mm`,
              color: color,
            });
          }
        }
      });
  }

  getOpenbite(
    imageType: ORTHO_IMAGE_TYPE,
    toothNumbers: string[],
    key: string,
    color: string
  ) {
    toothNumbers?.sort(this.numericSort)?.forEach((toothNumber) => {
      if (toothNumber) {
        const ortho_general = this.jsonContent.ortho_general;

        let openBiteLineGeometry = [];
        let openBiteMM = 0;

        switch (imageType) {
          case ORTHO_IMAGE_TYPE.INTRA_FRONTAL_OCCLUSION:
            openBiteLineGeometry =
              ortho_general?.frontal_findings?.openbite_lines?.[toothNumber] ??
              [];
            openBiteMM =
              ortho_general?.frontal_findings?.openbite_mm_dict?.[
                toothNumber
              ] ?? 0;
            break;
          case ORTHO_IMAGE_TYPE.INTRA_LATERAL_RIGHT:
          case ORTHO_IMAGE_TYPE.INTRA_LATERAL_LEFT:
            openBiteLineGeometry =
              ortho_general?.posterior_openbite?.openbite_lines?.[
                toothNumber
              ] ?? [];
            openBiteMM =
              ortho_general?.posterior_openbite?.openbite_mm_dict?.[
                toothNumber
              ] ?? 0;
            break;
        }

        const openBiteMMRounded = Number(openBiteMM?.toFixed(0) ?? 0);

        if (openBiteLineGeometry?.length && openBiteMMRounded > 0) {
          let openBiteLine = {
            uuid: v4(),
            color,
            geometry: [openBiteLineGeometry],
            geometry_labels: openBiteMMRounded,
            unit: "mm",
            hidden: false,
            key: `${key}(${toothNumber})`,
            parentKey: key,
            polygonType: "line",
            pxToMM: this.pxToMM,
            enablePxToMM: this.enablePxToMM,
          };
          this.polygons[openBiteLine.uuid] = openBiteLine;

          this.sidePanel.push({
            key: `${key}(${toothNumber})`,
            description: `${key}(${toothNumber})`,
            value: `${openBiteMMRounded} mm`,
            color,
          });
        }
      }
    });
  }

  getFullArchCrowding(molar2molar_insufficiency: number) {
    const { geometry } = this.jsonContent.ortho_general?.arch?.molar ?? {
      geometry: [],
    };

    if (geometry?.length > 0) {
      let fullArchCrowding = {
        uuid: v4(),
        color: ORTHO_COLORS.FULL_ARCH_CROWDING,
        geometry: [geometry],
        geometry_labels: molar2molar_insufficiency,
        unit: "mm",
        hidden: false,
        key: "Full arch crowding",
        parentKey: "Full arch crowding",
        polygonType: "arch",
        pxToMM: this.pxToMM,
        enablePxToMM: this.enablePxToMM,
      };
      this.polygons[fullArchCrowding.uuid] = fullArchCrowding;

      this.sidePanel.push({
        key: "Full arch crowding",
        description: "Full arch crowding",
        value: `${molar2molar_insufficiency} mm`,
        color: ORTHO_COLORS.FULL_ARCH_CROWDING,
      });
    }
  }

  getAnteriorCrowding(canine2canine_insufficiency: number) {
    const { geometry } = this.jsonContent.ortho_general?.arch?.anterior ?? {
      geometry: [],
    };

    if (geometry?.length > 0) {
      let anteriorCrowding = {
        uuid: v4(),
        color: ORTHO_COLORS.ANTERIOR_CROWDING,
        geometry: [geometry],
        geometry_labels: canine2canine_insufficiency,
        unit: "mm",
        hidden: false,
        key: "Anterior crowding",
        parentKey: "Anterior crowding",
        polygonType: "arch",
        pxToMM: this.pxToMM,
        enablePxToMM: this.enablePxToMM,
      };
      this.polygons[anteriorCrowding.uuid] = anteriorCrowding;
      this.sidePanel.push({
        key: "Anterior crowding",
        description: "Anterior crowding",
        value: `${canine2canine_insufficiency} mm`,
        color: ORTHO_COLORS.ANTERIOR_CROWDING,
      });
    }
  }

  getToothGeometry(
    listTeeth: string[],
    key: string,
    color: string,
    polygonType: string
  ) {
    let listValidTooth: string[] = [];
    listTeeth?.sort(this.numericSort).forEach((toothNumber, index) => {
      if (toothNumber) {
        const { geometry } = this.listTeeth?.[toothNumber] ?? {
          geometry: [],
        };

        if (geometry?.length > 0) {
          let tooth = {
            uuid: v4(),
            color: color,
            geometry: [geometry[0]],
            description: key,
            hidden: false,
            key: key,
            parentKey: key,
            polygonType: polygonType,
            pxToMM: this.pxToMM,
            enablePxToMM: this.enablePxToMM,
          };
          this.polygons[tooth.uuid] = tooth;
          listValidTooth.push(toothNumber);
        }
      }
    });
    if (listValidTooth.length) {
      this.sidePanel.push({
        key: key,
        description: key,
        value: listValidTooth?.sort(this.numericSort)?.join(",") ?? "",
        color: color,
      });
    }
  }

  getLabioLingualSpread(labio_lingual_spread: number) {
    if (labio_lingual_spread > 0) {
      const { points, mm } = this.jsonContent?.ortho_general
        ?.labiolingual_spread?.labio_lingual_line ?? {
        points: [],
        mm: 0,
      };

      const roundedMM = mm ?? 0;
      if (points && points.length && roundedMM > 0) {
        let labioLingualLine = {
          uuid: v4(),
          color: ORTHO_COLORS.LABIO_LINGUAL_SPREAD,
          geometry: [points],
          geometry_labels: labio_lingual_spread,
          unit: "mm",
          key: "Labio-lingual spread",
          parentKey: "Labio-lingual spread",
          polygonType: "line",
          pxToMM: this.pxToMM,
          enablePxToMM: this.enablePxToMM,
        };
        this.polygons[labioLingualLine.uuid] = labioLingualLine;

        this.sidePanel.push({
          key: "Labio-lingual spread",
          description: "Labio-lingual spread",
          value: `${labio_lingual_spread} mm`,
          color: ORTHO_COLORS.LABIO_LINGUAL_SPREAD,
        });
      }
    }
  }

  getOverJet() {
    const { overjet_line } = this.jsonContent?.ortho_general?.measurements ?? {
      overjet_line: [],
    };
    const { overjet, reverse_overjet } = this.claimCephFinding || {};
    let overjetMeasument = 0;
    let color = "";
    let key = "";
    if (overjet_line?.length > 0) {
      if (reverse_overjet && reverse_overjet > 0) {
        overjetMeasument = reverse_overjet;
        color = ORTHO_COLORS.REVERSE_OVERJET;
        key = "Reverse overjet";
      } else if (overjet && overjet > 0) {
        overjetMeasument = overjet;
        color = ORTHO_COLORS.OVERJET;
        key = "Overjet";
      }

      if (overjetMeasument) {
        let overJetLine = {
          uuid: v4(),
          color,
          geometry: [overjet_line],
          geometry_labels: overjetMeasument,
          unit: "mm",
          hidden: false,
          key,
          parentKey: "overjet",
          polygonType: "line",
          horizontal: true,
          pxToMM: this.pxToMM,
          enablePxToMM: this.enablePxToMM,
        };
        this.polygons[overJetLine.uuid] = overJetLine;

        this.sidePanel.push({
          key,
          description: key,
          value: `${overjetMeasument} mm`,
          color,
        });
      }
    }
  }

  getRulerDetails() {
    const pixel_to_mm =
      this.jsonContent.ortho_general?.pixel_calibration?.pixel_to_mm ?? 0;
    if (pixel_to_mm) {
      let rulerLine = {
        uuid: v4(),
        pixelCalibration: pixel_to_mm,
        key: "PixelCalibration",
        parentKey: "Calibration",
        polygonType: "ruler",
        pxToMM: this.pxToMM,
        enablePxToMM: this.enablePxToMM,
      };
      this.polygons[rulerLine.uuid] = rulerLine;
    }
  }

  getCephFindings(claimCephFinding: CephFinding) {
    this.claimCephFinding = claimCephFinding;
    this.getOverJet();
    this.getOverBite();
    this.getRulerDetails();
    return {
      imageUri: this.imageUri,
      show: true,
      title: "Cephalometric Findings",
      type: ORTHO_IMAGE_TYPE.CEPH,
      polygons: this.polygons,
      sidePanel: this.sidePanel,
      options: {
        intersection_point:
          this.jsonContent?.ortho_general?.measurements?.intersection_point,
      } as OrthoImagePredictionOptions,
    } as OrthoImagePrediction;
  }

  getOcclusalUpperFindings(claimUpperFinding: OcclusionFinding) {
    this.claimOcclusionUpperFinding = claimUpperFinding;
    const {
      molar2molar_insufficiency,
      canine2canine_insufficiency,
      labio_lingual_spread,
    } = this.claimOcclusionUpperFinding;

    if (this.claimOcclusionUpperFinding.ectopic) {
      this.getToothGeometry(
        this.claimOcclusionUpperFinding.ectopic,
        "Ectopic",
        ORTHO_COLORS.ECTOPIC,
        "polygon"
      );
    }

    if (
      typeof molar2molar_insufficiency === "number" &&
      molar2molar_insufficiency >= 0
    ) {
      this.getFullArchCrowding(molar2molar_insufficiency);
    }

    if (
      typeof canine2canine_insufficiency === "number" &&
      canine2canine_insufficiency >= 0
    ) {
      this.getAnteriorCrowding(canine2canine_insufficiency);
    }

    // Ensure the side panel order is Anterior Crowding followed by Full Arch Crowding
    // This is necessary because drawing Full Arch Crowding after Anterior Crowding
    // can overwrite the Anterior Crowding in the visualization, but the side panel
    // should still display Anterior Crowding first.
    this.swapAnteriorAndFullArchCrowdingEntries();

    // if (this.claimOcclusionUpperFinding.crowded) {
    //   this.getToothGeometry(
    //     this.claimOcclusionUpperFinding.crowded,
    //     "Crowded",
    //     ORTHO_COLORS.CROWDED,
    //     "arch"
    //   );
    // }
    // if (this.claimOcclusionUpperFinding.rotated) {
    //   this.getToothGeometry(
    //     this.claimOcclusionUpperFinding.rotated,
    //     "Rotated",
    //     ORTHO_COLORS.ROTATED,
    //     "arch"
    //   );
    // }
    // this.getSpacingOpen();
    // if (this.claimOcclusionUpperFinding.spacing_closed) {
    //   this.getToothGeometry(
    //     this.claimOcclusionUpperFinding.spacing_closed,
    //     "Spacing closed",
    //     ORTHO_COLORS.SPACING_CLOSED,
    //     "polygon"
    //   );
    // }
    this.getLabioLingualSpread(labio_lingual_spread ?? 0);
    this.getRulerDetails();
    return {
      imageUri: this.imageUri,
      show: true,
      title: "Maxillary Occlusal Findings",
      type: ORTHO_IMAGE_TYPE.INTRA_OCCLUSAL_UPPER,
      polygons: this.polygons,
      sidePanel: this.sidePanel,
    } as OrthoImagePrediction;
  }

  getFrontalRestFindings() {
    return {
      imageUri: this.imageUri,
      show: true,
      title: "Frontal Open Findings",
      type: ORTHO_IMAGE_TYPE.INTRA_FRONTAL_REST,
      polygons: this.polygons,
      sidePanel: this.sidePanel,
    } as OrthoImagePrediction;
  }

  getOcclusalLowerFindings(claimLowerFinding: OcclusionFinding) {
    this.claimOcclusionLowerFinding = claimLowerFinding;
    const {
      molar2molar_insufficiency,
      canine2canine_insufficiency,
      labio_lingual_spread,
    } = this.claimOcclusionLowerFinding;

    if (this.claimOcclusionLowerFinding.ectopic) {
      this.getToothGeometry(
        this.claimOcclusionLowerFinding.ectopic,
        "Ectopic",
        ORTHO_COLORS.ECTOPIC,
        "polygon"
      );
    }

    if (
      typeof molar2molar_insufficiency === "number" &&
      molar2molar_insufficiency >= 0
    ) {
      this.getFullArchCrowding(molar2molar_insufficiency);
    }

    if (
      typeof canine2canine_insufficiency === "number" &&
      canine2canine_insufficiency >= 0
    ) {
      this.getAnteriorCrowding(canine2canine_insufficiency);
    }

    // Ensure the side panel order is Anterior Crowding followed by Full Arch Crowding
    // This is necessary because drawing Full Arch Crowding after Anterior Crowding
    // can overwrite the Anterior Crowding in the visualization, but the side panel
    // should still display Anterior Crowding first.
    this.swapAnteriorAndFullArchCrowdingEntries();

    // if (this.claimOcclusionLowerFinding.crowded) {
    //   this.getToothGeometry(
    //     this.claimOcclusionLowerFinding.crowded,
    //     "Crowded",
    //     ORTHO_COLORS.CROWDED,
    //     "arch"
    //   );
    // }
    // if (this.claimOcclusionLowerFinding.rotated) {
    //   this.getToothGeometry(
    //     this.claimOcclusionLowerFinding.rotated,
    //     "Rotated",
    //     ORTHO_COLORS.ROTATED,
    //     "arch"
    //   );
    // }
    // this.getSpacingOpen();
    // if (this.claimOcclusionLowerFinding.spacing_closed) {
    //   this.getToothGeometry(
    //     this.claimOcclusionLowerFinding.spacing_closed,
    //     "Spacing closed",
    //     ORTHO_COLORS.SPACING_CLOSED,
    //     "polygon"
    //   );
    // }
    this.getLabioLingualSpread(labio_lingual_spread ?? 0);

    this.getRulerDetails();
    return {
      imageUri: this.imageUri,
      show: true,
      title: "Mandibular Occlusal Findings",
      type: ORTHO_IMAGE_TYPE.INTRA_OCCLUSAL_LOWER,
      polygons: this.polygons,
      sidePanel: this.sidePanel,
    } as OrthoImagePrediction;
  }

  getLateralLeftFindings(claimLateralLeftFinding: PosteriorFinding) {
    this.claimLateralLeftFinding = claimLateralLeftFinding;
    if (this.claimLateralLeftFinding.ectopic) {
      this.getToothGeometry(
        this.claimLateralLeftFinding.ectopic,
        "Ectopic",
        ORTHO_COLORS.ECTOPIC,
        "polygon"
      );
    }
    if (this.claimLateralLeftFinding?.class_III_discrepancy?.left_teeth) {
      this.getToothGeometry(
        this.claimLateralLeftFinding.class_III_discrepancy.left_teeth,
        "A-P discrepancy",
        ORTHO_COLORS.ANTERIOR_POSTERIOR_DISCREPANCY,
        "arch"
      );
    }
    if (this.claimLateralLeftFinding.crossbite) {
      this.getToothGeometry(
        this.claimLateralLeftFinding.crossbite,
        "Crossbite",
        ORTHO_COLORS.CROSSBITE,
        "polygon"
      );
    }
    if (this.claimLateralLeftFinding?.openbite?.length) {
      this.getOpenbite(
        ORTHO_IMAGE_TYPE.INTRA_LATERAL_LEFT,
        this.claimLateralLeftFinding.openbite,
        "Openbite",
        ORTHO_COLORS.OPENBITE
      );
    }
    this.getRulerDetails();
    return {
      imageUri: this.imageUri,
      show: true,
      title: "Left Lateral Findings",
      type: ORTHO_IMAGE_TYPE.INTRA_LATERAL_LEFT,
      polygons: this.polygons,
      sidePanel: this.sidePanel,
    } as OrthoImagePrediction;
  }

  getFrontalOcclusalFindings(claimFrontalFinding: FrontalFinding) {
    this.claimFrontalFinding = claimFrontalFinding;
    if (this.claimFrontalFinding.ectopic) {
      this.getToothGeometry(
        this.claimFrontalFinding.ectopic,
        "Ectopic",
        ORTHO_COLORS.ECTOPIC,
        "polygon"
      );
    }
    if (this.claimFrontalFinding.overbite) {
      this.getOverbiteBox(
        this.claimFrontalFinding.overbite,
        "Overbite",
        ORTHO_COLORS.OVERBITE
      );
    }
    if (this.claimFrontalFinding.crossbite) {
      this.getToothGeometry(
        this.claimFrontalFinding.crossbite,
        "Crossbite",
        ORTHO_COLORS.CROSSBITE,
        "polygon"
      );
    }
    if (this.claimFrontalFinding?.openbite?.frontal_upper_jaw?.length) {
      this.getOpenbite(
        ORTHO_IMAGE_TYPE.INTRA_FRONTAL_OCCLUSION,
        this.claimFrontalFinding.openbite.frontal_upper_jaw,
        "Openbite",
        ORTHO_COLORS.OPENBITE
      );
    }
    this.getRulerDetails();
    return {
      imageUri: this.imageUri,
      show: true,
      title: "Frontal Closed Findings",
      type: ORTHO_IMAGE_TYPE.INTRA_FRONTAL_OCCLUSION,
      polygons: this.polygons,
      sidePanel: this.sidePanel,
    } as OrthoImagePrediction;
  }

  getLateralRightFindings(claimLateralRightFinding: PosteriorFinding) {
    this.claimLateralRightFinding = claimLateralRightFinding;
    if (this.claimLateralRightFinding.ectopic) {
      this.getToothGeometry(
        this.claimLateralRightFinding.ectopic,
        "Ectopic",
        ORTHO_COLORS.ECTOPIC,
        "polygon"
      );
    }
    if (this.claimLateralRightFinding?.class_III_discrepancy?.right_teeth) {
      this.getToothGeometry(
        this.claimLateralRightFinding.class_III_discrepancy.right_teeth,
        "A-P discrepancy",
        ORTHO_COLORS.ANTERIOR_POSTERIOR_DISCREPANCY,
        "arch"
      );
    }
    if (this.claimLateralRightFinding.crossbite) {
      this.getToothGeometry(
        this.claimLateralRightFinding.crossbite,
        "Crossbite",
        ORTHO_COLORS.CROSSBITE,
        "polygon"
      );
    }
    if (this.claimLateralRightFinding.openbite) {
      this.getOpenbite(
        ORTHO_IMAGE_TYPE.INTRA_LATERAL_RIGHT,
        this.claimLateralRightFinding.openbite,
        "Openbite",
        ORTHO_COLORS.OPENBITE
      );
    }
    this.getRulerDetails();
    return {
      imageUri: this.imageUri,
      show: true,
      title: "Right Lateral Findings",
      type: ORTHO_IMAGE_TYPE.INTRA_LATERAL_RIGHT,
      polygons: this.polygons,
      sidePanel: this.sidePanel,
    } as OrthoImagePrediction;
  }

  getPanoFindings(claimPanoFinding: PanoFinding) {
    this.claimPanoFinding = claimPanoFinding;

    if (this.claimPanoFinding.impacted) {
      this.getToothGeometry(
        this.claimPanoFinding.impacted,
        "Impacted",
        ORTHO_COLORS.IMPACTED,
        "polygon"
      );
    }
    if (this.claimPanoFinding.ectopic) {
      this.getToothGeometry(
        this.claimPanoFinding.ectopic,
        "Ectopic",
        ORTHO_COLORS.ECTOPIC,
        "polygon"
      );
    }

    this.getRulerDetails();
    return {
      imageUri: this.imageUri,
      show: true,
      title: "Panoramic Findings",
      type: ORTHO_IMAGE_TYPE.PAN,
      polygons: this.polygons,
      sidePanel: this.sidePanel,
    } as OrthoImagePrediction;
  }

  numericSort(a: string, b: string): number {
    const numA = Number(a);
    const numB = Number(b);
    if (isNaN(numA) && isNaN(numB)) {
      return a.localeCompare(b);
    } else if (isNaN(numA)) {
      return 1;
    } else if (isNaN(numB)) {
      return -1;
    } else {
      return numA - numB;
    }
  }

  tnPairSort(a: SpacingOpen, b: SpacingOpen): number {
    return (
      Math.min(...a.tn_pair.map(Number)) - Math.min(...b.tn_pair.map(Number))
    );
  }

  swapAnteriorAndFullArchCrowdingEntries() {
    const anteriorCrowdingIndex = this.sidePanel.findIndex(
      (item) => item.color === ORTHO_COLORS.ANTERIOR_CROWDING
    );
    const fullArchCrowdingIndex = this.sidePanel.findIndex(
      (item) => item.color === ORTHO_COLORS.FULL_ARCH_CROWDING
    );

    if (anteriorCrowdingIndex !== -1 && fullArchCrowdingIndex !== -1) {
      const temp = this.sidePanel[anteriorCrowdingIndex];
      this.sidePanel[anteriorCrowdingIndex] =
        this.sidePanel[fullArchCrowdingIndex];
      this.sidePanel[fullArchCrowdingIndex] = temp;
    }
  }

  getPixelToMM() {
    return this.jsonContent.ortho_general?.pixel_calibration?.pixel_to_mm ?? 1;
  }
}
