import { notification } from "antd";
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  from,
  Observable,
} from "apollo-boost";
import { onError } from "apollo-link-error";

import { cleanTypenameLink } from "src/configs/cleanTypenameLink";
import { ERROR_MSGS } from "src/constants/errors";
import authService from "src/services/auth.service";
import cacheService from "src/services/cache.service";
import userService from "src/services/user.service";

export function initializeApollo(uri: string | undefined) {
  if (!uri) {
    throw new Error(ERROR_MSGS.GRAPHQL_URI_MISSING);
  }

  const logoutLink = onError(({ networkError }) => {
    if (
      typeof networkError === "object" &&
      networkError !== null &&
      "statusCode" in networkError &&
      networkError.statusCode === 401
    ) {
      if (authService.getAuth()) {
        authService.saveUserInfo().then(async (backup) => {
          const gcpTenantId = cacheService.getItemsSync("auth")?.gcpTenantId;
          const accessToken = (await authService.getFirebaseToken()) || "";
          await userService.signOut(
            accessToken,
            userService.getActiveClientId(),
            gcpTenantId
          );
          authService.signOut().finally(() => {
            cacheService.setItemSync("logoutBackup", backup);
            const { pathname, search } = window.location;
            let lastURL = pathname + search;
            cacheService.setItemSync(
              "URLbeforeSessionExpire",
              JSON.parse(JSON.stringify(lastURL))
            );
            notification.error({
              description: "You must login to proceed with application",
              message: "Session Timeout",
            });
            setTimeout(() => {
              window.location.href = window.location.origin;
            }, 500);
          });
        });
      }
    }
  });

  const link = new ApolloLink((operation, forward) => {
    return new Observable((observer) => {
      // Ensure Firebase is initialized
      authService
        .getFirebaseToken()
        .then((accessToken) => {
          const gcpTenantId = authService.getAuth()?.gcpTenantId;
          const activeClientId = userService.getActiveClientId();
          const authClientId = userService.getAuthClientId();

          let headers: any = {
            Authorization: `Bearer ${accessToken}`,
            "x-oj-client-id": activeClientId,
          };

          if (authClientId.split(",").length > 1) {
            headers = {
              ...headers,
              "x-oj-account-pick-client-id": activeClientId,
              "x-oj-client-id": authClientId,
            };
          }

          if (gcpTenantId) {
            headers = {
              ...headers,
              "X-OJ-TENANT-ID": gcpTenantId,
            };
          }

          operation.setContext({ headers });
          const subscription = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });

          return () => {
            if (subscription) subscription.unsubscribe();
          };
        })
        .catch((error) => {
          console.error("Error getting token", error);
          observer.error(error);
        });
    });
  }).concat(new HttpLink({ uri }));

  const cache = new InMemoryCache();
  const client = new ApolloClient({
    cache,
    link: from([cleanTypenameLink, logoutLink, link]),
  });
  return client;
}
